<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Экспертное заключени"
        :counter="$store.getters.getDocuments.length"
        :button="{ title: 'Добавить экспертное заключение', link: '/admin/expert_opinions/create?type=create' }"
      />
      <main>
        <table>
          <tr>
            <td><p>Название документа</p></td>
            <td><p>Дата создания</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in $store.getters.getDocuments" :key="`country${item.id}`">
            <td><p>{{ item.name }}</p></td>
            <td><p>{{ item.createDate }}</p></td>
            <td class="drop-icon">
              <drop-list @clickItem="clickItem"
                    :id-item="item.id"
                    :edit-link="`general_editing_doc/${item.id}`"/>
            </td>
          </tr>
        </table>
        <pagination/>
      </main>
  </div>
</template>

<script>
import DropList from '@/components/Admin/DropList'
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
export default {
  name: 'Documents',
  data () {
    return {
      filers: {
        name: ''
      },
      systemTagList: ['/expert_opinions']
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить документ?')
        if (consent) {
          this.$store.dispatch('deleteDocuments', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
    }
  },
  mounted () {
    const formData = new FormData()
    formData.append('systemTagList', JSON.stringify(this.systemTagList))
    this.$store.dispatch('setDocuments', {
      formData,
      settings: {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      }
    })
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
}
</style>
